import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes_g = cx({
        'cholesterol-graph--figures': true,
        'cholesterol-graph--green_figures': true,
        'cholesterol-graph--green_figures--ru': locale === 'ru'
    });
    const classes_y = cx({
        'cholesterol-graph--figures': true,
        'cholesterol-graph--yellow_figures': true,
        'cholesterol-graph--yellow_figures--ru': locale === 'ru'
    });
    const classes_r = cx({
        'cholesterol-graph--figures': true,
        'cholesterol-graph--red_figures': true,
        'cholesterol-graph--red_figures--ru': locale === 'ru'
    });
    const classes = cx({
        'cholesterol-graph--header_rest': true,
        'cholesterol-graph--header_rest--ru': locale === 'ru'
    });
    return (
        <div className="cholesterol">
            <div className="cholesterol-header__text">
                <FormattedMessage id="cholesterol.header" />
            </div>
            <div className="cholesterol-content">
                <div className="cholesterol-content__item">
                    <img
                        src="/static/svg/cholesterol-point.svg"
                    />
                    <div className="cholesterol-content__text">
                        <FormattedMessage id="cholesterol.item.1" />
                    </div>
                </div>
                <div className="cholesterol-content__item">
                    <img
                        src="/static/svg/cholesterol-point.svg"
                    />
                    <div 
                        className="cholesterol-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'cholesterol.item.2'})}}
                    />
                </div>
                <div className="cholesterol-content__item">
                    <img
                        src="/static/svg/cholesterol-point.svg"
                    />
                    <div className="cholesterol-content__text">
                        <FormattedMessage id="cholesterol.item.3" />
                    </div>
                </div>
                <div className="cholesterol-graph">
                    <picture>
                        <source 
                            media="(max-width: 900px)"
                            srcSet="/static/images/cholesterol-graph__img--mobile.png"
                        />
                        <img 
                            className="cholesterol-graph__img"
                            alt=""
                            src="/static/images/cholesterol-graph__img.png"
                        />
                    </picture>
                    <div 
                        className="cholesterol-graph--header_first"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'cholesterol.graph.1'})}}
                    />
                    <div className={classes}>
                        <FormattedMessage id="cholesterol.graph.2" />
                    </div>
                    <div 
                        className={classes_g}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'cholesterol.graph.3'})}}
                    />
                    <div className="cholesterol-graph--text cholesterol-graph--green_text">
                        <FormattedMessage id="cholesterol.graph.4" />
                    </div>
                    <div 
                        className={classes_y}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'cholesterol.graph.5'})}}
                    />
                    <div className="cholesterol-graph--text cholesterol-graph--yellow_text">
                        <FormattedMessage id="cholesterol.graph.6" />
                    </div>
                    <div 
                        className={classes_r}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'cholesterol.graph.7'})}}
                    />
                    <div className="cholesterol-graph--text cholesterol-graph--red_text">
                        <FormattedMessage id="cholesterol.graph.8" />
                    </div>
                    <div 
                        className="cholesterol-graph--footer"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'cholesterol.graph.9'})}}
                    />
                </div>
            </div>
        </div>
    );
})
