import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
        return (
        <div className="plate" id="plate">
            <div className="plate-header__text">
                <FormattedMessage id="plate.header" />
            </div>
            <div className="plate-content">
                <div className="plate-content__item">
                    <img
                        src="/static/svg/plate-point.svg"
                    />
                    <div 
                        className="plate-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'plate.item.1'})}}
                    />
                </div>
                <div className="plate-content__item">
                    <img
                        src="/static/svg/plate-point.svg"
                    />
                    <div 
                        className="plate-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'plate.item.2'})}}
                    />
                </div>
            </div>
            <div className="plate-suggestion">
                <div className="plate-suggestion__header">
                    <FormattedMessage id="plate.suggestion.header" />
                </div>
                <div className="plate-suggestion__item">
                    <FormattedMessage id="plate.suggestion.1" />
                </div>
                <div className="plate-suggestion__item">
                    <FormattedMessage id="plate.suggestion.2" />
                </div>
                <div className="plate-suggestion__item">
                    <FormattedMessage id="plate.suggestion.3" />
                </div>
                <div className="plate-suggestion__item">
                    <FormattedMessage id="plate.suggestion.4" />
                </div>
                <div className="plate-suggestion__item">
                    <FormattedMessage id="plate.suggestion.5" />
                </div>
                <div className="plate-suggestion__item">
                    <FormattedMessage id="plate.suggestion.6" />
                </div>
                <div 
                    className="plate-suggestion__credit"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'plate.suggestion.credit'})}}
                />
                <img src="/static/svg/plate-suggestion-idea.svg" alt="" className="plate-suggestion-img" />
            </div>
            <div className="plate-img">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet={ locale === 'ru' ? "/static/images/plate-img-plate--375-ru.png" : "/static/images/plate-img-plate--375.png" }
                    />
                    <img 
                        className="plate-img__image" 
                        alt=""
                        src={ locale === 'ru' ? "/static/images/plate-img-plate-ru.png" : "/static/images/plate-img-plate.png" }
                    />
                </picture>
            </div>
        </div>
    );
})
