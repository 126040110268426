import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="zovacor-free">
        <div className="zovacor-free-header__text">
            <FormattedMessage id="zovacor.free.header" />
        </div>
        <div className="zovacor-free-content--wrap">
            <div className="zovacor-free-content">
                <div className="zovacor-free-content__item">
                    <img
                        src="/static/svg/zovacor-free-point.svg"
                    />
                    <div 
                        className="zovacor-free-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'zovacor.free.item.1'})}}
                    />                    
                </div>
                <div className="zovacor-free-content__item">
                    <img
                        src="/static/svg/zovacor-free-point.svg"
                    />
                    <div className="zovacor-free-content__text">
                        <FormattedMessage id="zovacor.free.item.2" />
                    </div>
                </div>
                <div className="zovacor-free-content__item">
                    <img
                        src="/static/svg/zovacor-free-point.svg"
                    />
                    <div 
                        className="zovacor-free-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'zovacor.free.item.3'})}}
                    />                    
                </div>
            </div>
            <div className="zovacor-free-content__image">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/images/zovacor-free-img--mobile.png"
                    />
                    <img 
                        className="zovacor-free-content__image--img"
                        alt=""
                        src="/static/images/zovacor-free-img.png"
                    />
                </picture>
            </div>
        </div>
    </div>
));
