import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'warning-signs-header__text': true,
        'warning-signs-header__text--ru': locale === 'ru'
    });
    const classes_head = cx({
        'warning-signs-content-graph__text': true,
        'warning-signs--head': true,
        'warning-signs--head--ru': locale === 'ru'
    });
    const classes_chest = cx({
        'warning-signs-content-graph__text': true,
        'warning-signs--chest': true,
        'warning-signs--chest--ru': locale === 'ru'
    });
    const classes_gut = cx({
        'warning-signs-content-graph__text': true,
        'warning-signs--gut': true,
        'warning-signs--gut--ru': locale === 'ru'
    });
    const classes_limbs = cx({
        'warning-signs-content-graph__text': true,
        'warning-signs--limbs': true,
        'warning-signs--limbs--ru': locale === 'ru'
    });
    const classes_s1 = cx({
        'warning-signs-content-graph__text': true,
        'warning-signs--aterosclerosis-1': true,
        'warning-signs--aterosclerosis-1--ru': locale === 'ru'
    });
    const classes_s3 = cx({
        'warning-signs-content-graph__text': true,
        'warning-signs--aterosclerosis-3': true,
        'warning-signs--aterosclerosis-3--ru': locale === 'ru'
    });
    return (
        <div className="warning-signs">
            <div className={classes}>
                <FormattedMessage id="warning.signs.header" />
            </div>
            <div className="warning-signs-content">
                <div className="warning-signs-content__image">
                    <picture>
                        <source 
                            media="(max-width: 375px)"
                            srcSet="/static/images/warning-signs-graph--375.png"
                        />
                        <source 
                            media="(max-width: 900px)"
                            srcSet="/static/images/warning-signs-graph--mobile.png"
                        />
                        <img
                            src="/static/images/warning-signs-graph.png"
                            alt=""
                            className="warning-signs-graph"
                        />
                    </picture>
                    <div 
                        className={classes_head}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'warning.signs.graph.1'})}}
                    />
                    <div 
                        className={classes_chest}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'warning.signs.graph.2'})}}
                    />
                    <div 
                        className={classes_gut}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'warning.signs.graph.3'})}}
                    />
                    <div 
                        className="warning-signs-content-graph__text warning-signs--libido"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'warning.signs.graph.4'})}}
                    />
                    <div 
                        className={classes_limbs}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'warning.signs.graph.5'})}}
                    />
                    <div className="warning-signs-content-graph__text warning-signs--normal">
                        <FormattedMessage id="warning.signs.graph.6" />
                    </div>
                    <div className="warning-signs-content-graph__text warning-signs--aterosclerosis">
                        <FormattedMessage id="warning.signs.graph.7" />
                    </div>
                    <div 
                        className={classes_s1}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'warning.signs.graph.8'})}}
                    />
                    <div 
                        className="warning-signs-content-graph__text warning-signs--aterosclerosis-2"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'warning.signs.graph.9'})}}
                    />
                    <div 
                        className={classes_s3}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'warning.signs.graph.10'})}}
                    />
                    <div 
                        className="warning-signs-content-graph__text warning-signs--aterosclerosis-4"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'warning.signs.graph.11'})}}
                    />
                </div>
            </div>
        </div>
    );
})
