import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'oleuropein__arrows__text': true,
        'oleuropein__arrows__text--right': true,
        'oleuropein__arrows__text--right--ru': locale === 'ru'
    });
    return (
        <div className="oleuropein">
            <div className="oleuropein-header__text">
                <FormattedMessage id="oleuropein.header" />
            </div>
            <div className="oleuropein-content">
                <div className="oleuropein-content__item">
                    <img
                        src="/static/svg/oleuropein-point.svg"
                    />
                    <div className="oleuropein-content__text">
                        <FormattedMessage id="oleuropein.item.1" />
                    </div>
                </div>
                <div className="oleuropein-content__item">
                    <img
                        src="/static/svg/oleuropein-point.svg"
                    />
                    <div className="oleuropein-content__text">
                        <FormattedMessage id="oleuropein.item.2" />
                    </div>
                </div>
                <div className="oleuropein-content__item">
                    <img
                        src="/static/svg/oleuropein-point.svg"
                    />
                    <div 
                        className="oleuropein-content__text"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'oleuropein.item.3'})}}
                    />
                </div>
            </div>
            <div className="oleuropein__arrows">
                <img 
                    className="oleuropein__arrows-img"
                    alt=""
                    src="/static/svg/oleuropein-arrows.svg"
                />
                <div 
                    className="oleuropein__arrows__text oleuropein__arrows__text--left"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'oleuropein.arrow.left'})}}
                />
                <div 
                    className={classes}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'oleuropein.arrow.right'})}}
                />
            </div>
        </div>
    );
})
