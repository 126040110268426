import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'equals__image__percent__text': true,
        'equals__image__percent__text--ru': locale === 'ru'
    });
    const classes_txt = cx({
        'equals__image__text': true,
        'equals__image__text--ru': locale === 'ru'
    });
    return (
        <div className="equals">
            <div className="equals-header__text">
                <FormattedMessage id="equals.header" />
            </div>
            <div className="equals__image">
                <picture>
                    <source 
                        media="(max-width: 500px)"
                        srcSet="/static/images/equals-banner--mobile.png"
                    />
                    <img 
                        className="equals__image--img"
                        alt=""
                        src="/static/images/equals-banner.png"
                    />
                </picture>
                <div className="equals__image--red">
                    <div className="equals__image__percent">
                        <FormattedMessage id="equals.text.1" />
                    </div>
                    <div className={classes}>
                        <FormattedMessage id="equals.text.2" />
                    </div>
                </div>
                <div className="equals__image--green">
                    <div className="equals__image__percent">
                        <FormattedMessage id="equals.text.3" />
                    </div>
                    <div className={classes}>
                        <FormattedMessage id="equals.text.4" />
                    </div>
                </div>
                <div className="equals__image--blue">
                    <div className="equals__image__percent">
                        <FormattedMessage id="equals.text.5" />
                    </div>
                    <div className={classes}>
                        <FormattedMessage id="equals.text.6" />
                    </div>
                </div>
                <div 
                    className={classes_txt}
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'equals.text.7'})}}
                />            
                <div className="equals__image__footer">
                    <FormattedMessage id="equals.text.8" />
                </div>
            </div>
        </div>
    );
})
