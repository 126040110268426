import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage} from "react-intl";

export default ({locale}) => {
    const classes = cx({
        'blood-pressure-graph__info__text': true,
        'blood-pressure-graph__info__text--ru': locale === 'ru'
    });
    const classes_legend = cx({
        'blood-pressure-graph--legend': true,
        'blood-pressure-graph--legend--ru': locale === 'ru'
    });

    const classes_et = cx({
        'blood-pressure-graph--text': true,
        'blood-pressure-graph--et': true,
        'blood-pressure-graph--et--ru': locale === 'ru'
    });
    const classes_lt = cx({
        'blood-pressure-graph--text': true,
        'blood-pressure-graph--lt': true,
        'blood-pressure-graph--lt--ru': locale === 'ru'
    });
    const classes_lv = cx({
        'blood-pressure-graph--text': true,
        'blood-pressure-graph--lv': true,
        'blood-pressure-graph--lv--ru': locale === 'ru'
    });
    const classes_fi = cx({
        'blood-pressure-graph--text': true,
        'blood-pressure-graph--fi': true,
        'blood-pressure-graph--fi--ru': locale === 'ru'
    });
    const classes_sw = cx({
        'blood-pressure-graph--text': true,
        'blood-pressure-graph--sw': true,
        'blood-pressure-graph--sw--ru': locale === 'ru'
    });
    const classes_it = cx({
        'blood-pressure-graph--text': true,
        'blood-pressure-graph--it': true,
        'blood-pressure-graph--it--ru': locale === 'ru'
    });
    const classes_fr = cx({
        'blood-pressure-graph--text': true,
        'blood-pressure-graph--fr': true,
        'blood-pressure-graph--fr--ru': locale === 'ru'
    });
    return (
        <div className="blood-pressure">
            <div className="blood-pressure-header__text">
                <FormattedMessage id="blood.pressure.header" />
            </div>
            <div className="blood-pressure-graph">
                <div className="blood-pressure-content">
                    <div className="blood-pressure-content__item">
                        <img
                            src="/static/svg/blood-pressure-point.svg"
                        />
                        <div className="blood-pressure-content__text">
                            <FormattedMessage id="blood.pressure.item.1" />
                        </div>
                    </div>
                    <div className="blood-pressure-content__item">
                        <img
                            src="/static/svg/blood-pressure-point.svg"
                        />
                        <div className="blood-pressure-content__text">
                            <FormattedMessage id="blood.pressure.item.2" />
                        </div>
                    </div>
                    <div className="blood-pressure-content__item">
                        <img
                            src="/static/svg/blood-pressure-point.svg"
                        />
                        <div className="blood-pressure-content__text">
                            <FormattedMessage id="blood.pressure.item.3" />
                        </div>
                    </div>
                </div>
                <div className="blood-pressure-graph--info">
                    <img alt="" src="/static/svg/blood-pressure-graph__img--info.svg" className="blood-pressure-graph__img--info" />
                    <div className={classes}>
                        <FormattedMessage id="blood.pressure.graph.1" />
                    </div>
                </div>
                <div className="blood-pressure-graph--container">
                    <div className={classes_legend}>
                        <FormattedMessage id="blood.pressure.graph.2" />
                    </div>
                    <picture>
                        <source 
                            media="(max-width: 600px)"
                            srcSet="/static/svg/blood-pressure-graph__img--mobile.svg"
                        />
                        <img 
                            className="blood-pressure-graph__img"
                            alt=""
                            src="/static/svg/blood-pressure-graph__img.svg"
                        />
                    </picture>
                    <div className={classes_et}>
                        <FormattedMessage id="blood.pressure.graph.3" />
                    </div>
                    <div className={classes_lt}>
                        <FormattedMessage id="blood.pressure.graph.4" />
                    </div>
                    <div className={classes_lv}>
                        <FormattedMessage id="blood.pressure.graph.5" />
                    </div>
                    <div className={classes_fi}>
                        <FormattedMessage id="blood.pressure.graph.6" />
                    </div>
                    <div className={classes_sw}>
                        <FormattedMessage id="blood.pressure.graph.7" />
                    </div>
                    <div className={classes_it}>
                        <FormattedMessage id="blood.pressure.graph.8" />
                    </div>
                    <div className={classes_fr}>
                        <FormattedMessage id="blood.pressure.graph.9" />
                    </div>
                    <div className="blood-pressure-graph--text--sm blood-pressure-graph--men">
                        <FormattedMessage id="blood.pressure.graph.10" />
                    </div>
                    <div className="blood-pressure-graph--text--sm blood-pressure-graph--women">
                        <FormattedMessage id="blood.pressure.graph.11" />
                    </div>
                    <div className="blood-pressure-graph--footer">
                        <FormattedMessage id="blood.pressure.graph.12" />
                    </div>
                </div>
            </div>
        </div>
    );
}
