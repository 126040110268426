import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {FormattedMessage} from "react-intl";

export default () => (
    <div className="cardiologists" id="cardiologists">
        <HeaderText/>
        <div  className="cardiologists-content">
            <div  className="cardiologists-image">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/images/cardiologists-doctor--mobile.png"
                    />
                    <img 
                        className="cardiologists-image--img"
                        alt=""
                        src="/static/images/cardiologists-doctor.png"
                    />
                </picture>
            </div>
            <div className="cardiologists-items">
            <Item
                logo="/static/images/cardiologists-rice.png"
                text ={<FormattedMessage id="cardiologists.item.1" />}
            >     
            </Item>
            <Item
                logo="/static/images/cardiologists-fats.png"
                text={<FormattedMessage id="cardiologists.item.2" />}
            >     
            </Item>
            <Item
                logo="/static/images/cardiologists-fibre.png"
                text={<FormattedMessage id="cardiologists.item.3" />}
            >   
            </Item>
            <Item
                logo="/static/images/cardiologists-run.png"
                text={<FormattedMessage id="cardiologists.item.4" />}
            >    
            </Item>
            <Item
                logo="/static/images/cardiologists-weight.png"
                text={<FormattedMessage id="cardiologists.item.5" />}
            >    
            </Item>
        </div>
        </div>
    </div>
);