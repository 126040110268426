import React from 'react';
import './Locations.css';

export default () => (
    <div className="footer-locations">
        <a target="_blank" href="https://www.ipharma.ee/suda-ja-veri/kolesterool">
            <img
                src="/static/svg/ipharma.svg"
            />
        </a>
        <a target="_blank" href="https://www.benu.ee/zovacor">
            <img
                src="/static/svg/benu.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.apotheka.ee/tooted/suda-ja-veresooned?brand=ZOVACOR">
            <img
                src="/static/svg/apotheka.svg"
            />
        </a>
        <a target="_blank" href="https://www.azeta.ee/search?q=ZOVACOR">
            <img
                src="/static/svg/euro-apteek.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.sudameapteek.ee/tooted/suda-ja-vereringe?brand=ZOVACOR">
            <img
                src="/static/svg/sydame-apteek.svg"
            />
        </a>
    </div>
);
