import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="lights" id="lights">
        <div className="lights-header__text">
            <FormattedMessage id="lights.header" />
        </div>
        <picture>
            <source 
                media="(max-width: 375px)"
                srcSet="/static/images/lights-img--375.png"
            />
            <img 
                className="lights-img" 
                alt="" 
                src="/static/images/lights-img.png" 
            />
        </picture>
        <div className="lights-content">
            <table className="lights-table lights-small">
                <tbody>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.1.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.1.2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.2.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.2.2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.3.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.3.2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.4.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.4.2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.5.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.5.2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.6.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.6.2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.7.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.7.2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.8.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.8.2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.9.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.9.2" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-between" colSpan="2"></td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.1.1" />
                        </td>
                        <td className="lights-table-yellow">
                            <FormattedMessage id="lights.table.1.3" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.2.1" />
                        </td>
                        <td className="lights-table-yellow">
                            <FormattedMessage id="lights.table.2.3" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.3.1" />
                        </td>
                        <td className="lights-table-yellow">
                            <FormattedMessage id="lights.table.3.3" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.7.1" />
                        </td>
                        <td className="lights-table-yellow">
                            <FormattedMessage id="lights.table.7.3" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.8.1" />
                        </td>
                        <td className="lights-table-yellow">
                            <FormattedMessage id="lights.table.8.3" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.9.1" />
                        </td>
                        <td className="lights-table-yellow">
                            <FormattedMessage id="lights.table.9.3" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-between" colSpan="2"></td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.1.1" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.1.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.2.1" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.2.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.3.1" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.3.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.4.1" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.4.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.5.1" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.5.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.6.1" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.6.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.7.1" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.7.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.8.1" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.8.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.9.1" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.9.4" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className="lights-table lights-large">
                <tbody>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.1.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.1.2" />
                        </td>
                        <td className="lights-table-yellow">
                            <FormattedMessage id="lights.table.1.3" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.1.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.2.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.2.2" />
                        </td>
                        <td className="lights-table-yellow">
                            <FormattedMessage id="lights.table.2.3" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.2.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.3.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.3.2" />
                        </td>
                        <td className="lights-table-yellow">
                            <FormattedMessage id="lights.table.3.3" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.3.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.4.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.4.2" />
                        </td>
                        <td className="lights-table-yellow">
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.4.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.5.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.5.2" />
                        </td>
                        <td className="lights-table-yellow">
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.5.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.6.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.6.2" />
                        </td>
                        <td className="lights-table-yellow">
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.6.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.7.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.7.2" />
                        </td>
                        <td className="lights-table-yellow">
                            <FormattedMessage id="lights.table.7.3" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.7.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.8.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.8.2" />
                        </td>
                        <td className="lights-table-yellow">
                            <FormattedMessage id="lights.table.8.3" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.8.4" />
                        </td>
                    </tr>
                    <tr>
                        <td className="lights-table-grey">
                            <FormattedMessage id="lights.table.9.1" />
                        </td>
                        <td className="lights-table-green">
                            <FormattedMessage id="lights.table.9.2" />
                        </td>
                        <td className="lights-table-yellow">
                            <FormattedMessage id="lights.table.9.3" />
                        </td>
                        <td className="lights-table-red">
                            <FormattedMessage id="lights.table.9.4" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div 
                className="lights-credit"
                dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'lights.credit'})}}
            />
        </div>
    </div>
));
