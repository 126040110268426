import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const classes = cx({
        'red-rice__banner__text--top': true,
        'red-rice__banner__text--top--ru': locale === 'ru'
    });
    return (
        <div id="red-rice">
            <div className="red-rice">
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/images/red-rice-img--mobile.png"
                    />
                    <img 
                        className="red-rice-img"
                        alt=""
                        src="/static/images/red-rice-img.png"
                    />
                </picture>
                <div className="red-rice-header__text">
                    <FormattedMessage id="red.rice.header" />
                </div>
                <div className="red-rice__wrap">
                    <div className="red-rice-content">
                        <div className="red-rice-content__item">
                            <img
                                src="/static/svg/red-rice-point.svg"
                            />
                            <div className="red-rice-content__text">
                                <FormattedMessage id="red.rice.item.1" />
                            </div>
                        </div>
                        <div className="red-rice-content__item">
                            <img
                                src="/static/svg/red-rice-point.svg"
                            />
                            <div className="red-rice-content__text">
                                <FormattedMessage id="red.rice.item.2" />
                            </div>
                        </div>
                        <div className="red-rice-content__item">
                            <img
                                src="/static/svg/red-rice-point.svg"
                            />
                            <div className="red-rice-content__text">
                                <FormattedMessage id="red.rice.item.3" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="red-rice__banner">
                    <picture>
                        <source 
                            media="(max-width: 900px)"
                            srcSet="/static/svg/red-rice-banner--mobile.svg"
                        />
                        <img 
                            className="red-rice__banner-img"
                            alt=""
                            src="/static/svg/red-rice-banner.svg"
                        />
                    </picture>
                    <img 
                        className="red-rice__banner-arrow"
                        alt=""
                        src="/static/svg/red-rice-arrow.svg"
                    />
                    <div className={classes}>
                        <FormattedMessage id="red.rice.banner.1" />
                    </div>
                    <div 
                        className="red-rice__banner__text--bottom"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'red.rice.banner.2'})}}
                    />
                </div>
            </div>
        </div>
    );
})
