import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="diet" id="diet">
        <div className="diet-header__text">
            <FormattedMessage id="diet.header" />
        </div>
        <picture>
            <source 
                media="(max-width: 375px)"
                srcSet="/static/images/diet-banner--375.png"
            />
            <img 
                className="diet-header__image" 
                alt="" 
                src="/static/images/diet-banner.png" 
            />
        </picture>
        <div className="diet-content">
            <div className="diet-content__item">
                <img
                    src="/static/svg/diet-point.svg"
                />
                <div 
                    className="diet-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'diet.item.1'})}}
                />
            </div>
            <div className="diet-content__item">
                <img
                    src="/static/svg/diet-point.svg"
                />
                <div 
                    className="diet-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'diet.item.2'})}}
                />
            </div>
            <div className="diet-content__item">
                <img
                    src="/static/svg/diet-point.svg"
                />
                <div 
                    className="diet-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'diet.item.3'})}}
                />
            </div>
            <div className="diet-instrucion">
                <FormattedMessage id="diet.instruction" />
            </div>
        </div>
    </div>
));
