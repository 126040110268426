import React from 'react';
import './index.css';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="aterosclerosis" id="aterosclerosis">
        <div className="aterosclerosis-header__text">
            <FormattedMessage id="aterosclerosis.header" />
        </div>
        <img className="aterosclerosis-header__image" alt="" src="/static/images/aterosclerosis-banner.png" />
        <div className="aterosclerosis-content">
            <div className="aterosclerosis-content__item">
                <img
                    src="/static/svg/aterosclerosis-point.svg"
                />
                <div 
                    className="aterosclerosis-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'aterosclerosis.item.1'})}}
                />
            </div>
            <div className="aterosclerosis-content__item">
                <img
                    src="/static/svg/aterosclerosis-point.svg"
                />
                <div 
                    className="aterosclerosis-content__text"
                    dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'aterosclerosis.item.2'})}}
                />
            </div>
        </div>
    </div>
));
