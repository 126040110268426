import React, {Component} from 'react';
import {IntlProvider} from 'react-intl';
import Header from './components/sections/header'
import TopBanner from './components/sections/top-banner';
import ProtectHeart from './components/sections/protect-heart';
import Aterosclerosis from './components/sections/aterosclerosis';
import WarningSigns from './components/sections/warning-signs';
import BloodVessels from './components/sections/blood-vessels';
import RiskFactors from './components/sections/risk-factors';
import Cholesterol from './components/sections/cholesterol';
import BloodPressure from './components/sections/blood-pressure';
import Equals from './components/sections/equals';
import MiddleBuyNow from './components/sections/middle-buy-now';
import RedRice from './components/sections/red-rice';
import Oleuropein from './components/sections/oleuropein';
import ZovacorFree from './components/sections/zovacor-free';
import Fats from './components/sections/fats';
import Diet from './components/sections/diet';
import Plate from './components/sections/plate';
import Lights from './components/sections/lights';
import Cardiologists from './components/sections/cardiologists';
import Specialists from './components/sections/specialists';
import Share from './components/sections/share';
import Footer from './components/sections/footer';
import messages from "./locales/messages";

class Page extends Component {
    render() {
        const { locale } = this.props;
        return (
            <IntlProvider onError={() => {}} locale={locale} messages={messages[locale]}>
                <div className="page">
                    <Header locale={locale} />
                    <TopBanner locale={locale} />
                    <ProtectHeart/>
                    <Aterosclerosis/>
                    <WarningSigns locale={locale} />
                    <BloodVessels locale={locale} />
                    <RiskFactors locale={locale} />
                    <Cholesterol locale={locale} />
                    <BloodPressure locale={locale} />
                    <Equals locale={locale} />
                    <MiddleBuyNow/>
                    <RedRice locale={locale} />
                    <Oleuropein locale={locale} />
                    <ZovacorFree/>
                    <Fats/>
                    <Diet/>
                    <Plate locale={locale} />
                    <Lights/>
                    <Cardiologists/>
                     <Specialists/>
                    <Share/>
                    <Footer locale={locale}/>
                </div>
            </IntlProvider>
        );
    }
}

export default Page;
