import React from 'react';
import './index.css';
import cx from 'classnames';
import {FormattedMessage, injectIntl} from "react-intl";

export default injectIntl(({intl, locale}) => {
    const longterm = cx({
        'blood-vessels-content-graph__text': true,
        'blood-vessels-graph-top': true,
        'blood-vessels-graph-longterm': true,
        'blood-vessels-graph-longterm--ru': locale === 'ru'
    });
    const medium = cx({
        'blood-vessels-content-graph__text': true,
        'blood-vessels-graph-top': true,
        'blood-vessels-graph-medium': true,
        'blood-vessels-graph-medium--ru': locale === 'ru'
    });
    const immediate = cx({
        'blood-vessels-content-graph__text': true,
        'blood-vessels-graph-top': true,
        'blood-vessels-graph-immediate': true,
        'blood-vessels-graph-immediate--ru': locale === 'ru'
    });
    const thickening = cx({
        'blood-vessels-content-graph__text': true,
        'blood-vessels-graph-legend': true,
        'blood-vessels-graph-thickening': true,
        'blood-vessels-graph-thickening--ru': locale === 'ru'
    });
    const inflammation = cx({
        'blood-vessels-content-graph__text': true,
        'blood-vessels-graph-legend': true,
        'blood-vessels-graph-inflammation': true,
        'blood-vessels-graph-inflammation--ru': locale === 'ru'
    });
    return (
        <div className="blood-vessels">
            <div className="blood-vessels-header__text">
                <FormattedMessage id="blood.vessels.header" />
            </div>
            <div className="blood-vessels-graph">
                <div className="blood-vessels-graph__image">
                    <picture>
                        <source 
                            media="(max-width: 375px)"
                            srcSet="/static/images/blood-vessels-img--375.png"
                        />
                        <img
                            src="/static/images/blood-vessels-img.png"
                            alt=""
                            className="blood-vessels-graph__image--img"
                        />
                    </picture>
                    <div 
                        className={longterm}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.longterm'})}}
                    />
                    <div 
                        className={medium}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.medium'})}}
                    />
                    <div 
                        className={immediate}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.immediate'})}}
                    />
                    <div 
                        className="blood-vessels-content-graph__text blood-vessels-graph-year blood-vessels-graph-10"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.10'})}}
                    />
                    <div 
                        className="blood-vessels-content-graph__text blood-vessels-graph-year blood-vessels-graph-20"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.20'})}}
                    />
                    <div 
                        className="blood-vessels-content-graph__text blood-vessels-graph-year blood-vessels-graph-30"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.30'})}}
                    />
                    <div 
                        className="blood-vessels-content-graph__text blood-vessels-graph-year blood-vessels-graph-40"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.40'})}}
                    />
                    <div 
                        className="blood-vessels-content-graph__text blood-vessels-graph-year blood-vessels-graph-50"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.50'})}}
                    />
                    <div 
                        className="blood-vessels-content-graph__text blood-vessels-graph-year blood-vessels-graph-60"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.60'})}}
                    />
                    <div 
                        className={inflammation}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.inflammation'})}}
                    />
                    <div 
                        className={thickening}
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.thickening'})}}
                    />
                    <div 
                        className="blood-vessels-content-graph__text blood-vessels-graph-legend blood-vessels-graph-naast"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.naast'})}}
                    />
                    <div 
                        className="blood-vessels-content-graph__text blood-vessels-graph-legend blood-vessels-graph-unstable"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.unstable'})}}
                    />
                    <div 
                        className="blood-vessels-content-graph__text blood-vessels-graph-legend blood-vessels-graph-ripped"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.ripped'})}}
                    />
                    <div 
                        className="blood-vessels-content-graph__text blood-vessels-graph-legend blood-vessels-graph-tromb"
                        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.graph.tromb'})}}
                    />
                </div>
            </div>
            <div className="blood-vessels-text--wrap">
                <div className="blood-vessels-text">
                    <div className="blood-vessels-warn">
                        <img src="/static/svg/blood-vessels-warn.svg" className="blood-vessels-text__useful--img"/>
                        <div 
                            className="blood-vessels-text__useful"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.useful'})}}
                        />
                    </div>
                    <div className="blood-vessels-text-item">
                        <img
                            src="/static/svg/blood-vessels-point.svg"
                        />
                        <div 
                            className="blood-vessels-text__text"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.item.1'})}}
                        />
                    </div>
                    <div className="blood-vessels-text-item">
                        <img
                            src="/static/svg/blood-vessels-point.svg"
                        />
                        <div 
                            className="blood-vessels-text__text"
                            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'blood.vessels.item.2'})}}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
})
